import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, { attrs: { xs6: "" } }, [
            _c("span", { staticClass: "titlePromo" }, [
              _vm._v("Promo Management")
            ]),
            _c("span", { staticClass: "subtitlePromo" }, [
              _vm._v("All Promotions")
            ])
          ]),
          _c(VFlex, { attrs: { xs6: "" } }, [
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "newPromoBtn",
                    attrs: { type: "submit", color: "primary" },
                    on: { click: _vm.newPromotion }
                  },
                  [
                    _c(VIcon, { attrs: { left: "", dark: "" } }, [
                      _vm._v("mdi-plus")
                    ]),
                    _vm._v("New Promotion\n        ")
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "table-length-row" },
            [
              _c(
                VFlex,
                { attrs: { md5: "", xs5: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Search Promotions",
                      "single-line": "",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      clearable: ""
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.multigroupOptions,
                      "item-text": "name",
                      label: "Select App",
                      "return-object": "",
                      solo: "",
                      "single-line": ""
                    },
                    model: {
                      value: _vm.selectedApp,
                      callback: function($$v) {
                        _vm.selectedApp = $$v
                      },
                      expression: "selectedApp"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            {
              attrs: { xs12: "" },
              on: {
                click: function($event) {
                  return _vm.enableTableSorting($event)
                }
              }
            },
            [
              _c(VDataTable, {
                staticClass: "data-table",
                attrs: {
                  "no-data-text": _vm.loading
                    ? "Please wait while data is loading"
                    : "No promotion found",
                  headers: _vm.headers,
                  items: _vm.promotionsProxy,
                  search: _vm.filter,
                  loading: _vm.loading,
                  pagination: _vm.pagination,
                  "custom-sort": _vm.sortPromotionsTable
                },
                on: {
                  "update:pagination": function($event) {
                    _vm.pagination = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        _c("tr", [
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(item.label.en))])
                          ]),
                          _c("td", [_c("span", [_vm._v(_vm._s(item.type))])]),
                          _c("td", [
                            _c("span", [
                              _vm._v(_vm._s(item.company_name) + " ")
                            ])
                          ]),
                          _c(
                            "td",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              on: {
                                click: function($event) {
                                  return _vm.openSitesDialog(item)
                                }
                              }
                            },
                            [
                              _c("a", [
                                _vm._v(_vm._s(_vm.siteCount(item)) + " Sites")
                              ])
                            ]
                          ),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "schedule-span" },
                              [
                                _c(VIcon, [_vm._v("mdi-calendar-blank")]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getSheduleDateString(item.calendar)
                                    ) +
                                    "\n              "
                                )
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "schedule-span" },
                              [
                                _c(VIcon, [_vm._v("mdi-clock")]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getScheduleTimeString(item.calendar)
                                    ) +
                                    "\n              "
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                VChip,
                                {
                                  staticClass: "status justify-center",
                                  attrs: {
                                    "text-color": _vm.textColor(item.status),
                                    color: _vm.chipColor(item.status),
                                    disabled: "",
                                    draggable: "false"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("capitalize")(item.status))
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "buttonsDiv" },
                              [
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "buttonActions",
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "blue"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editPromotion(
                                                          item.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-pencil")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Edit Promotion")])]
                                ),
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "buttonActions",
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "blue"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.clonePromotion(
                                                          item,
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-content-copy")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Clone Promotion")])]
                                ),
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "buttonActions",
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "blue",
                                                      disabled:
                                                        item.status.toLowerCase() ===
                                                        "expired"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.continuePausePromotion(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.status.toLowerCase() ===
                                                          "paused"
                                                          ? "mdi-play"
                                                          : "mdi-pause"
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.status.toLowerCase() === "paused"
                                            ? "Continue"
                                            : "Pause"
                                        ) + "\n                    Promotion"
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "buttonActions",
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "red"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deletePromo(
                                                          item.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Delete Promotion")])]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.sitesDialog,
            callback: function($$v) {
              _vm.sitesDialog = $$v
            },
            expression: "sitesDialog"
          }
        },
        [
          _vm.selectedPromo
            ? _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
                    _c("div", { staticClass: "label-flow" }, [
                      _vm._v(_vm._s(_vm.selectedPromo.label.en || ""))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "v-card-content" },
                    [
                      _c(
                        VLayout,
                        [
                          _c(VFlex, { attrs: { xs12: "" } }, [
                            _c("b", [_vm._v("Sites")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "mt-2" },
                        [
                          _c(
                            VFlex,
                            {
                              staticStyle: {
                                height: "200px",
                                overflow: "scroll"
                              },
                              attrs: { xs12: "" }
                            },
                            _vm._l(_vm.getSites(_vm.selectedPromo), function(
                              resource,
                              rIndex
                            ) {
                              return _c(
                                "div",
                                {
                                  key: rIndex,
                                  staticStyle: { height: "25px" }
                                },
                                [
                                  _c(VLayout, [
                                    _vm._v(_vm._s(resource.name))
                                  ])
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticStyle: { padding: "15px" } },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.editPromotion(_vm.selectedPromo.id)
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }