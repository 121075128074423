<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs6>
        <span class="titlePromo">Promo Management</span>
        <span class="subtitlePromo">All Promotions</span>
      </v-flex>
      <v-flex xs6>
        <div class="right">
          <v-btn type="submit" color="primary" class="newPromoBtn" @click="newPromotion">
            <v-icon left dark>mdi-plus</v-icon>New Promotion
          </v-btn>
        </div>
      </v-flex>
      <div class="table-length-row">
        <v-flex md5 xs5>
          <v-text-field
            label="Search Promotions"
            single-line
            solo
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="filter"
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-select
            :items="multigroupOptions"
            item-text="name"
            label="Select App"
            return-object
            solo
            single-line
            v-model="selectedApp"
          ></v-select>
        </v-flex>
      </div>
      <v-flex xs12 @click="enableTableSorting($event)">
        <v-data-table
          :no-data-text="loading ? 'Please wait while data is loading' : 'No promotion found'"
          :headers="headers"
          :items="promotionsProxy"
          :search="filter"
          :loading="loading"
          class="data-table"
          :pagination.sync="pagination"
          :custom-sort="sortPromotionsTable"
        >
          <template v-slot:items="{ item, index }">
            <tr>
              <td>
                <span>{{ item.label.en }}</span>
              </td>
              <td>
                <span>{{ item.type }}</span>
              </td>
              <td>
                <span>{{ item.company_name }} </span>
              </td>
              <td style="text-decoration: underline;" @click="openSitesDialog(item)">
                <a>{{ siteCount(item) }} Sites</a>
              </td>
              <td>
                <span class="schedule-span"
                  ><v-icon>mdi-calendar-blank</v-icon>
                  {{ getSheduleDateString(item.calendar) }}
                </span>

                <span class="schedule-span"
                  ><v-icon>mdi-clock</v-icon>
                  {{ getScheduleTimeString(item.calendar) }}
                </span>
              </td>

              <td>
                <v-chip
                  class="status justify-center"
                  :text-color="textColor(item.status)"
                  :color="chipColor(item.status)"
                  disabled
                  draggable="false"
                  >{{ item.status | capitalize }}</v-chip
                >
              </td>
              <td>
                <div class="buttonsDiv">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        flat
                        icon
                        color="blue"
                        @click="editPromotion(item.id)"
                        class="buttonActions"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Promotion</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        flat
                        icon
                        color="blue"
                        @click="clonePromotion(item, index)"
                        class="buttonActions"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Clone Promotion</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        flat
                        icon
                        color="blue"
                        @click="continuePausePromotion(item)"
                        class="buttonActions"
                        :disabled="item.status.toLowerCase() === 'expired'"
                      >
                        <v-icon>{{
                          item.status.toLowerCase() === 'paused' ? 'mdi-play' : 'mdi-pause'
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >{{
                        item.status.toLowerCase() === 'paused' ? 'Continue' : 'Pause'
                      }}
                      Promotion</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        flat
                        icon
                        color="red"
                        @click="deletePromo(item.id)"
                        class="buttonActions"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Promotion</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="sitesDialog" width="600">
      <v-card v-if="selectedPromo">
        <v-card-title class="H4-Secondary-Center"
          ><div class="label-flow">{{ selectedPromo.label.en || '' }}</div></v-card-title
        >
        <div class="v-card-content">
          <v-layout>
            <v-flex xs12>
              <b>Sites</b>
            </v-flex>
          </v-layout>
          <v-layout class="mt-2">
            <v-flex xs12 style="height: 200px; overflow: scroll;">
              <div
                v-for="(resource, rIndex) of getSites(selectedPromo)"
                v-bind:key="rIndex"
                style="height: 25px;"
              >
                <v-layout>{{ resource.name }}</v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <v-card-actions style="padding: 15px;">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" flat @click="editPromotion(selectedPromo.id)">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import { PROMOTION_STATUS } from '@/constants';

export default {
  data: () => ({
    PROMOTION_STATUS,
    loading: false,
    pagination: {
      rowsPerPage: 10,
    },
    headers: [
      { text: 'Promotion Name', value: 'label.en', align: 'left' },
      { text: 'Type', value: 'type', align: 'left' },
      { text: 'Brand Name', value: 'company_name', align: 'left' },
      { text: 'Site', value: 'site', align: 'left' },
      { text: 'Schedule', value: 'schedule', sortable: false, align: 'left' },
      { text: 'Status', value: 'status', align: 'left' },
      { text: 'Actions', sortable: false, align: 'center' },
    ],
    filter: '',
    selectedApp: { name: 'All', id: 'all' },
    multigroupsMap: {},
    sitesDialog: false,
    selectedPromo: null,
    companiesAvailable: [],
    promoStatusMsg: {
      pause: 'Pause',
      continue: 'Continue',
    },
    wasTableManuallySorted: false,
    typeSortOrders: ['bogo', 'bundle', 'lto'],
    currentTypeSortOrder: 'bogo',
    statusSortOrders: ['active', 'paused', 'expired'],
    currentStatusSortOrder: 'active',
  }),
  computed: {
    ...mapState('promotions', ['promotions']),
    ...mapState('sites', ['siteMap', 'multigroups']),
    multigroupOptions() {
      return [{ name: 'All', id: 'all' }, ...this.multigroups];
    },
    promotionsProxy() {
      const sortedPromotions = [...this.promotions].sort((a, b) => {
        const defaultDate = { created: new Date(0).toISOString() };
        if (a.date === undefined) a.date = defaultDate;
        if (b.date === undefined) b.date = defaultDate;

        if (a.date.created < b.date.created) return 1;
        return a.date.created > b.date.created ? -1 : 0;
      });

      if (this.selectedApp && this.selectedApp.id === 'all') return sortedPromotions;
      return this.promotions.filter((promo) => promo.multigroup === this.selectedApp.id);
    },
  },
  methods: {
    ...mapActions('promotions', [
      'fetchPromotions',
      'deletePromotion',
      'putPromotion',
      'postPromotion',
      'setPromotionCompanies',
    ]),
    ...mapActions('sites', ['getLocationMultigroup']),
    ...mapActions('sectors', ['fetchSector']),
    async confirm(action) {
      return this.$confirm({
        title: `${this.capitalize(action)} Promotion?`,
        message: `Are you sure you want to ${action} this promotion?`,
        buttonTrueText: `${action.toUpperCase()}`,
        buttonFalseText: 'CANCEL',
      });
    },
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    newPromotion() {
      this.$router.push({
        name: 'promo-new',
      });
    },
    editPromotion(id) {
      this.$router.push({
        name: 'promo-edit',
        params: {
          promo_id: id,
        },
      });
    },
    siteCount(promo) {
      return (promo.location_group_ids && promo.location_group_ids.length) || 0;
    },
    async clonePromotion(promo, insertAfterPromo) {
      const clonsePromo = await this.confirm('Clone');
      if (!clonsePromo) return;
      const newPromo = cloneDeep(promo);
      newPromo.label.en = `Copy  - ${newPromo.label.en}`;
      delete newPromo.id;
      insertAfterPromo += 1;
      try {
        await this.postPromotion({ promo: newPromo, insertAfterPromo });

        this.$toast(`Promotion has been cloned`);
      } catch (error) {
        this.$toast.error(`Could not clone promotions`);
      }
    },
    async continuePausePromotion(promoToUpdate) {
      const promo = cloneDeep(promoToUpdate);
      if (promo.status.toLowerCase() === 'expired') return;

      const statusMsg =
        promo.status.toLowerCase() === this.PROMOTION_STATUS.paused
          ? this.promoStatusMsg.continue
          : this.promoStatusMsg.pause;

      const updatePromo = await this.confirm(statusMsg);

      if (!updatePromo) return;

      promo.status =
        statusMsg === this.promoStatusMsg.pause
          ? this.PROMOTION_STATUS.paused
          : this.PROMOTION_STATUS.active;
      try {
        await this.putPromotion({ promo });

        this.$toast(
          `Promotion has been ${statusMsg === this.promoStatusMsg.pause ? 'paused' : 'continued'}`,
        );
      } catch (error) {
        this.$toast.error(`Could not ${statusMsg} promotions`);
      }
    },
    async deletePromo(id) {
      const deletePromo = await this.confirm('delete');
      if (!deletePromo) return;
      try {
        await this.deletePromotion(id);

        this.$toast('Promotion has been deleted');
      } catch (error) {
        this.$toast.error('Could not delete promotions');
      }
    },
    getCompanyNameString(companyId) {
      if (!this.companiesAvailable || this.companiesAvailable.length === 0) return '';
      const companyDetails = this.companiesAvailable.find((company) => company.id === companyId);
      if (!companyDetails) return '';
      return companyDetails.label && companyDetails.label.en;
    },
    getSheduleDateString(calendar) {
      if (
        !calendar ||
        !calendar.events ||
        calendar.events.length === 0 ||
        !calendar.events[0].schedule.start ||
        !calendar.events[0].schedule.end
      )
        return '';

      const startDate = DateTime.local().set({
        year: calendar.events[0].schedule.start.year,
        month: calendar.events[0].schedule.start.month,
        day: calendar.events[0].schedule.start.day,
      });
      const endDate = DateTime.local().set({
        year: calendar.events[0].schedule.end.year,
        month: calendar.events[0].schedule.end.month,
        day: calendar.events[0].schedule.end.day,
      });

      return `${startDate.toFormat('DD')} - ${endDate.toFormat('DD')}`;
    },
    getScheduleTimeString(calendar) {
      if (
        !calendar ||
        !calendar.events ||
        calendar.events.length === 0 ||
        !calendar.events[0].schedule.start ||
        !calendar.events[0].schedule.end
      )
        return '';

      const timesToReturn = calendar.events.map((event) => {
        try {
          const today = DateTime.local();
          const startTime = event.schedule.times[0];
          const startTimes = startTime.split(':');
          const startDate =
            startTimes.length === 1
              ? today.set({ hour: startTimes[0] })
              : today.set({ hour: startTimes[0], minute: startTimes[1] });

          const endDate = startDate.plus({ minutes: event.schedule.duration });

          return `${startDate.toFormat('t')}-${endDate.toFormat('t')}`;
        } catch (error) {
          console.error(error);
        }
        return '';
      });
      return timesToReturn.join(' & ');
    },
    textColor(status) {
      if (status.toLowerCase() === 'active') return 'white';
      return 'black';
    },
    chipColor(status) {
      switch (status.toLowerCase()) {
        case 'active':
          return '#0a43a7';
        case 'scheduled':
          return '#ffecba';
        default:
          return '#f2f2f2';
      }
    },
    async getCompanies(multigroupId) {
      const sectorIds = new Set();
      const companyIds = new Set();
      this.companiesAvailable = [];
      const selectedMultigroup = this.multigroupsMap[multigroupId];
      if (!selectedMultigroup) return [];
      selectedMultigroup.groups.forEach((site) => {
        if (!site.locations) return;
        site.locations.forEach((location) => {
          if (!location.brands) return;
          location.brands.forEach((brand) => {
            if (!brand.sector) return;
            if (!sectorIds.has(brand.sector)) sectorIds.add(brand.sector);
            if (!companyIds.has(brand.company)) companyIds.add(brand.company);
          });
        });
      });

      let companies = [];
      try {
        const sectorsFetched = await Promise.all(
          [...sectorIds].map((sector_id) =>
            this.fetchSector({ sector_id, expanded: false, display_error_toast: false }),
          ),
        );

        companies = []
          .concat(...sectorsFetched.map((s) => s.companies))
          .filter((company) => companyIds.has(company && company.id));

        companies = cloneDeep(companies);

        companies.forEach((c) => {
          const sector = sectorsFetched.find((s) => s.id === c.sector);
          c.name = `${c.name}  (${sector.label && sector.label.en ? sector.label.en : ''})`;
        });
      } catch (error) {
        console.error(error);
      }
      return companies;
    },
    getSites(promo) {
      return (promo.location_group_ids || []).map((s) => ({
        ...this.siteMap[s],
      }));
    },
    openSitesDialog(promo) {
      this.sitesDialog = true;
      this.$set(this, 'selectedPromo', {
        ...promo,
      });
    },
    closeDialog() {
      this.sitesDialog = false;
      this.$set(this, 'selectedPromo', null);
    },
    compareValues(a, b, isDescending) {
      if (a > b) {
        return isDescending ? -1 : 1;
      }
      if (b > a) {
        return isDescending ? 1 : -1;
      }
      return 0;
    },
    enableTableSorting(event) {
      if (event.target.nodeName) this.wasTableManuallySorted = true;
    },
    sortPromotionsTable(items, index, isDescending) {
      if (!this.wasTableManuallySorted) {
        return items;
      }

      if (index === 'label.en') {
        items.sort((a, b) => {
          return this.compareValues(a.label.en, b.label.en, isDescending);
        });
      }

      if (index === 'type') {
        const currentIndex = this.typeSortOrders.indexOf(this.currentTypeSortOrder);
        const nextIndex = (currentIndex + 1) % this.typeSortOrders.length;

        items.sort((a, b) => {
          if (a.type === this.currentTypeSortOrder && b.type !== this.currentTypeSortOrder)
            return -1;
          if (a.type !== this.currentTypeSortOrder && b.type === this.currentTypeSortOrder)
            return 1;
          return 0;
        });

        this.currentTypeSortOrder = this.typeSortOrders[nextIndex];
      }

      if (index === 'company_name') {
        items.sort((a, b) => {
          return this.compareValues(a.company_name, b.company_name, isDescending);
        });
      }

      if (index === 'site') {
        items.sort((a, b) => {
          return this.compareValues(
            a.location_group_ids.length,
            b.location_group_ids.length,
            isDescending,
          );
        });
      }

      if (index === 'status') {
        const currentIndex = this.statusSortOrders.indexOf(this.currentStatusSortOrder);
        const nextIndex = (currentIndex + 1) % this.statusSortOrders.length;

        items.sort((a, b) => {
          if (a.status === this.currentStatusSortOrder && b.status !== this.currentStatusSortOrder)
            return -1;
          if (a.status !== this.currentStatusSortOrder && b.status === this.currentStatusSortOrder)
            return 1;
          return 0;
        });

        this.currentStatusSortOrder = this.statusSortOrders[nextIndex];
      }

      return items;
    },
  },
  async mounted() {
    this.loading = true;
    this.$store.commit('adminPanel/setLoading', true);
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Promotions',
      to: { name: 'promo' },
    });

    await this.fetchPromotions();
    if (!this.promotions || !this.promotions.length) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: 'Create New Promotion',
        to: { name: 'promo-new' },
      });
      this.$router.push({
        name: 'promo-new',
      });
      return;
    }

    try {
      const promoMultigroups = [...new Set(this.promotions.map((item) => item.multigroup))];

      const multigroupPromises = promoMultigroups.map(async (mulitgroupId) => {
        const multigroup = await this.getLocationMultigroup({
          id: mulitgroupId,
          extended: true,
          expanded: true,
          merge: false,
        });
        this.$set(this.multigroupsMap, mulitgroupId, multigroup);
      });

      await Promise.all(multigroupPromises);

      const companyPromises = promoMultigroups.map((multigroup) => {
        return this.getCompanies(multigroup);
      });

      this.companiesAvailable = (await Promise.all(companyPromises)).filter(Boolean).flat();
      this.setPromotionCompanies(this.companiesAvailable);
    } catch (error) {
      console.error(error);
      this.$toast.error('Could not load promotions');
    }

    this.$store.commit('adminPanel/setLoading', false);
    this.loading = false;
  },
};
</script>
<style scoped>
.table-length-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.label-flow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-table >>> .v-datatable thead {
  background: #fafafa;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.data-table >>> .v-datatable thead th {
  color: black;
}
.data-table >>> .v-datatable thead tr:not(.v-datatable__progress) th:first-child,
.data-table >>> .v-datatable tbody tr td:first-child {
  padding: 24px 12px 24px 24px !important;
}
.data-table >>> .v-datatable thead tr:not(.v-datatable__progress) th:last-child,
.data-table >>> .v-datatable tbody tr td:last-child {
  padding: 24px 24px 24px 12px !important;
}
.data-table >>> .v-btn:hover {
  color: #0a43a7 !important;
}

.data-table {
  box-shadow: 3px 3px 3px 3px #d8d8d8;
}
.status {
  width: 90px;
  height: 25px;
  border-radius: 4px;
  font-weight: bold;
  margin: 0 auto;
}
.schedule-span {
  display: block;
  margin-bottom: 15px;
}
.schedule-span:last-of-type {
  margin-bottom: 0;
}
.promoURL {
  text-decoration: underline;
}
.tableHeader {
  color: black;
}
.buttonActions {
  margin: 0 auto;
}
.buttonActions:first-of-type {
  margin-left: 0;
}
.buttonActions:last-of-type {
  margin-right: 0;
}
.buttonsDiv {
  display: flex;
}
.titlePromo {
  width: 421px;
  height: 63px;
  font-size: 46px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d73d8;
  display: block;
  margin-bottom: 29px;
  white-space: nowrap;
}
.subtitlePromo {
  width: 107px;
  height: 20px;
  font-size: 15.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
  margin-bottom: 26px;
  white-space: nowrap;
}
.newPromoBtn {
  height: 48px;
  padding-top: 0.25em;
  margin: 20px;
}
.filterIcon {
  width: 24px;
  height: 24px;
  color: black;
}
.filterBlock {
  padding: 20px 6px 8px 6px;
  width: fit-content;
  margin-left: auto;
}
.filterText {
  width: 48px;
  height: 27px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  padding-left: 15px;
}
</style>
